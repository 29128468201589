import * as Constants from '../Constants';
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import UserCard from '../components/UserCard'
import colors from '../utils/style/colors'
import { Loader } from '../utils/style/Atoms'
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import axios from "axios"
import Header from '../components/Header'

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const PageSubtitle = styled.h2`
	font-size: 20px;
	color: ${colors.secondary};
	font-weight: 300;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
export const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.2em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function Users() {
	const [usersArray, setUsersArray] = useState(null);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const [notif_count, set_notif_count] = useState(0);
	const { context } = useContext(UserContext)
	console.log("isLoading = ", isLoading)
	const navigate = useNavigate();

	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		setLoading('Chargement en cours...');
		const source = axios.CancelToken.source();
		console.log("gets all axios");
		axios.get(`${Constants.BACKEND_URL}user`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				setLoading(false);
				console.log("responseezf : ", response);
				setUsersArray(response.data);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get users error : ", error);
				setError(JSON.stringify(error));
			});
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
		return () => {
			source.cancel();
		}
	}, [])

	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			<LoaderWrapper>
				<StyledLink $isFullLink to={`/user/my_profile`} >Modifier mon profil</StyledLink>
			</LoaderWrapper>
			{usersArray ? (
				<div style={{ width: '100%' }}>{usersArray.length}&nbsp;Utilisateurs listés</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper>
					<Loader data-testid="loader" />
				</LoaderWrapper>
			) : (
				<CardsContainer>
					{usersArray?.map((user) => (
						<Link key={`user-${user._id}`} to={`/user/${user._id}`}>
							<UserCard
								title={user.title}
								birthday={getAge(user.birthday)}
								picture={`${Constants.BACKEND_URL}images/${user.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			<PageTitle>
				{isLoading && <p>{isLoading}</p>}
			</PageTitle>
			<PageSubtitle>
				{error && <p>{error}</p>}
			</PageSubtitle>
		</div>
	)
}

export default Users
