import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import axios from "axios"
import Header from '../components/Header'

const ActivityWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 1em 1em;
`
const ActivityDetails = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	width: 100%;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
	text-align: left;
`
const ErrorTitle = styled.h1`
	font-size: 2em;
	margin: 0;
	color: #b33232;
	font-weight: 600;
	background-color: #74992e;
`
const Location = styled.span`
	color: ${colors.secondary};
	text-align: left;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-right: 0.5em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function CreateActivity() {
	const { context } = useContext(UserContext);
	const [error, setError] = useState('');
	const [notif_count, set_notif_count] = useState(0);
	const navigate = useNavigate();
	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		// Check if server is up and if login token is expired :
		axios.get(`${Constants.BACKEND_URL}user/${context["user"]._id}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get User ok : ", response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])
	function validate_form() {
		console.log("script axios context['auth_token'] : " + context["auth_token"]);
		if ("" === $('#title').val()) {
			setError("Titre obligatoire !");
			return false;
		}
		if ("" === $('#date').val()) {
			setError("Date obligatoire !");
			return false;
		}
		return true;
	}
	function toIsoString(date) {
		// source https://stackoverflow.com/questions/17415579/how-to-iso-8601-format-a-date-with-timezone-offset-in-javascript
		// 2024-07-28T18:14:11+02:00					activity_date recu dans ce format string : 2024-07-27T04:02:00.000
		// new Date().toUTCString() = Sun, 28 Jul 2024 16:15:52 GMT
		// 			.toISOString() = 2024-07-28T16:16:02.416Z
		var tzo = -date.getTimezoneOffset(),
			dif = tzo >= 0 ? '+' : '-',
			pad = function (num) {
				return (num < 10 ? '0' : '') + num;
			};
		return date.getFullYear() +
			'-' + pad(date.getMonth() + 1) +
			'-' + pad(date.getDate()) +
			'T' + pad(date.getHours() + Math.floor(Math.abs(tzo) / 60)) +		// mon fix
			':' + pad(date.getMinutes()) +
			':' + pad(date.getSeconds()) /*+
			dif + pad(Math.floor(Math.abs(tzo) / 60)) +				// 2024-07-28T18:14:11+02:00		si on ajoute cette partie, 2024-07-28T18:24:54 sans cette partie
			':' + pad(Math.abs(tzo) % 60)*/;
	}
	function cancel_create() {
		if (window.confirm("Annuler la création de sortie ?")) {
			window.location.href = "/";
		}
	}
	function create_activity() {
		console.log("----------------------------- ");
		Date.prototype.addHours = function (h) {
			this.setTime(this.getTime() + (h * 60 * 60 * 1000));
			return this;
		}
		console.log("$('#date').val() : ", $('#date').val());
		const date_fix_hour = toIsoString(new Date($('#date').val()).addHours(-2)) + "Z"; // Date parsing already add 2 hours, so we have to remove 4 hours in summer, and 2 in winter !...
		// https://stackoverflow.com/questions/5619202/parsing-a-string-to-a-date-in-javascript
		// Best practice  = always store and make computations as UTC
		// To parse a date as UTC, append a Z - e.g.: new Date('2011-04-11T10:20:30Z')
		console.log("----------------------------- ");

		var activity = {
			"title": $('#title').val(),
			"date": date_fix_hour,
			"max_user_number": $('#max_user_number').val(),
			"access": $('#access').val(),
			"validation": $('#validation').val(),
			"additional_user_number": 0
		};
		console.log("create_activity : ", activity);
		if (!validate_form()) { return; }

		axios.post(`${Constants.BACKEND_URL}activity`, activity,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("script post axiazdazdos");
				console.log(response.data);
				if (response.data._id) {
					navigate('/activity/' + response.data._id); return;
				}
			}).catch(error => console.error(error));
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			<ActivityWrapper>
				<ActivityDetails>
					<TitleWrapper>
						<Location><StyledLink $isFullLink onClick={() => create_activity()}>Sauvegarder / Publier</StyledLink></Location>
						<Location><StyledLink $isFullLink onClick={() => cancel_create()}>Annuler</StyledLink></Location>
					</TitleWrapper>
					<TitleWrapper>
						<ErrorTitle>{error ? ("Erreur : " + error) : ("")}</ErrorTitle>&nbsp;
					</TitleWrapper>
					<TitleWrapper>
						<Location><input style={{ width: '30em' }} id="title" placeholder="Titre obligatoire ! " /></Location>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<input id="date" type="datetime-local" />
						</Location>
						<Title>Date*</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location><input id="max_user_number" type="number" defaultValue="6" size="50" /></Location>
						<Title>Nombre maximun de participants*</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<select id="access" size="3">
								<option value="public" selected="selected">Tous les utilisateurs connectés</option>
								<option value="link_only">Via le lien seulement</option>
							</select>
						</Location>
						<Title>Visibilité</Title>
					</TitleWrapper>
					<TitleWrapper>
						<Location>
							<select id="validation" size="2">
								<option value="automatic" selected="selected">Automatique</option>
								<option value="manual">Manuelle</option>
							</select>
						</Location>
						<Title>Validation des participants</Title>
					</TitleWrapper>
				</ActivityDetails>
			</ActivityWrapper>
		</div>
	)
}

export default CreateActivity
