import $ from 'jquery';
import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import ActivitySmallCard from '../components/ActivitySmallCard'
import ReviewCard from '../components/ReviewCard'
import UserSmallCard from '../components/UserSmallCard'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Loader } from '../utils/style/Atoms'
import { QRCodeSVG } from 'qrcode.react';
import PrivateMessageCard from '../components/PrivateMessageCard'
import axios from "axios"
import Linkify from 'react-linkify';
import Header from '../components/Header'
import dateFormat from 'dateformat';

const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-right: 0.3em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const TextLabels = styled.div`
	font-size: 0.8em;
`
const MessageCardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const MessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const Details = styled.h1`
	margin: 0.1em;
	font-size: 0.8em;
	color: #f5e4c7;
`
const UserWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopMessagesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const TopActivitiesWrapper = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(5, 1fr);
	width: 100%;
`
const TopReviewWrapper = styled.div`
	display: grid;
	gap: 0px;
	grid-template-columns: repeat(2, 1fr);
	width: 100%;
`
const HorizontalWrapper = styled.div`
	display: flex;
	flex-direction: line;
	margin-top: 0.8em;
	margin-bottom: 0.5em;
`
const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5em;
	color: white;
	width: 87%;
	text-align: left;
`
const Title = styled.h1`
	margin: 0.1em;
	font-size: 1em;
`
const Location = styled.span`
	color: ${colors.secondary};
`
const InputMessage = styled.span`
	width: 100%;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`
const CardImage = styled.img`
	width: 100%;
`
const PositionDiv = styled.img`
	position: absolute;
	border-radius: 50%;
`
function User() {
	var { id: queryId } = useParams();
	const { context } = useContext(UserContext);
	const [notif_count, set_notif_count] = useState(0);
	const [user_data, set_user_data] = useState({});
	const [is_user, set_is_user] = useState("");
	const [user_id, set_user_id] = useState("");
	const [link, setLink] = useState("");
	const [user_review, set_user_review] = useState("");
	const [contact_list, set_contact_list] = useState("");
	const [private_messages, set_private_messages] = useState({});
	const [inputSelectedFile, setInputSelectFile] = useState('')
	const [edit_page, set_edit_page] = useState(false);
	const [activity_user_link_data, setactivity_user_link_data] = useState({});
	const [x_coordinate, set_x_coordinate] = useState(0);
	const [y_coordinate, set_y_coordinate] = useState(0);
	const [x_relative_coordinate, set_x_relative_coordinate] = useState(0);
	const [y_relative_coordinate, set_y_relative_coordinate] = useState(0);
	const [position_marker_size, set_position_marker_size] = useState(0);
	const marker_size_factor = 25;
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); context["login_url_redirect"] = "/user/" + queryId; return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		if (context["user"]) {
			if (queryId === "my_profile") {
				queryId = context["user"]._id;
			}
			set_is_user(queryId === context["user"]._id);
		}
		set_user_id(queryId);
		get_user_data();
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])
	function set_toulouse_map_marker_position(data) {
		if (data) {
			if (document.getElementById("Toulouse_Map")) {
				var marker_size = window.innerWidth / marker_size_factor;
				var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
				set_x_relative_coordinate(data.x_coord * window.innerWidth + window.scrollX - marker_size * 1.5);
				set_y_relative_coordinate(data.y_coord * window.innerWidth + window.scrollY - marker_size * 1.5 + rect.top);
			}
		} else {
			console.error("Error no data ", data);
		}
	}
	function get_user_data() {
		console.log("get one User " + queryId);
		axios.get(`${Constants.BACKEND_URL}user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get User : ", response.data + " queryid " + queryId);
				set_user_data(response.data);
				if (response.data.x_coord > 0) {
					var marker_size = window.innerWidth / marker_size_factor;
					set_position_marker_size(marker_size);
					set_toulouse_map_marker_position(response.data);
				}
				if (!is_user) {
					get_user_link_data(response.data);
				} else {
					get_activity_user_link_data(response.data);
				}

			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}
	function get_user_link_data(data) {
		axios.get(`${Constants.BACKEND_URL}user_link/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get userLinkData : ", response.data);
				var link = response.data;
				if (link === null) {
					link = "null"; // needed cause null.status give "TypeError: Cannot read properties of null (reading 'status')"
				}
				if (link.status === "request") {
					link.status = link.userId === context["user"]._id ? "request_to_user" : "request_from_user";
				}
				setLink(link);
				if (link.status === "valid") {
					get_messages(data);
				} else {
					get_activity_user_link_data(data);
				}
			}).catch(error => console.error(error));
	}
	function get_messages(data) {
		console.log("get_messages with user : ", queryId);
		axios.get(`${Constants.BACKEND_URL}private_message/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get private_message response : ", response.data);
				set_private_messages(response.data);
				get_activity_user_link_data(data);
			}).catch(error => console.error(error));
	}
	function get_activity_user_link_data(data) {
		console.log("get_activity_user_link_data : ", queryId);
		axios.get(`${Constants.BACKEND_URL}activity_user_link/user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get activity_user_link_data : ", response.data);
				setactivity_user_link_data(response.data);
				get_user_reviews(data);
			}).catch(error => console.error(error));
	}
	function get_user_reviews(data) {
		axios.get(`${Constants.BACKEND_URL}review/user/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get get_user_reviews : ", response.data);
				set_user_review(response.data);
				get_contact_list(data);
			}).catch(error => console.error(error));
	}
	function get_contact_list(data) {
		axios.get(`${Constants.BACKEND_URL}user_link/all/${queryId}`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get get_contact_list : ", response.data);

				var contact_list = [];
				for (var contact of response.data) {
					console.log("contact : ", contact);
					if (contact.userId == queryId) {
						var contact_updated = contact;
						contact_updated.userId = contact.linked_userId;
						contact_updated.user_title = contact.linked_user_title;
						contact_updated.user_image = contact.linked_user_image;
						contact_updated.user_city = contact.linked_user_city;
						contact_list.push(contact_updated);
					} else {
						contact_list.push(contact);
					}
				}
				console.log("get contact_list : ", contact_list);
				set_contact_list(contact_list);
				set_toulouse_map_marker_position(data);
			}).catch(error => console.error(error));
	}
	function create_user_link_data() {
		console.log("create_user_link_data : ", queryId);
		axios.post(`${Constants.BACKEND_URL}user_link`, {
			"linked_userId": queryId
		},
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("create_user_link_data : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}
	function update_user_link_data(status) {
		console.log("update_user_link_data : ", status);
		axios.put(`${Constants.BACKEND_URL}user_link`, {
			"_id": link._id,
			"userId": link.userId,
			"linked_userId": link.linked_userId,
			"status": status
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("update_user_link_data response : ", response.data);
				get_user_link_data();
			}).catch(error => console.error(error));
	}
	function delete_user_link_data() {
		console.log("delete_user_link_data : ", link);
		if (window.confirm("Supprimer le contact ?")) {
			axios.delete(`${Constants.BACKEND_URL}user_link/${link._id}`,
				{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("delete_user_link_data response : ", response.data);
					get_user_link_data();
				}).catch(error => console.error(error));
		}
	}
	function save() {
		console.log("save user : ", queryId);
		console.log("inputSelectedFile : ", inputSelectedFile);
		setLoading(true);
		var user_data = {
			"city": $('#city').val(),
			"title": $('#title').val(),
			"name": $('#name').val(),
			"email": $('#email').val(),
			"birthday": $('#birthday').val(),
			"gender": $('#gender').val(),
			"surname": $('#surname').val(),
			"phone": $('#phone').val(),
			"description": $('#description').val(),
			"access": $('#access').val()
		}
		if (x_coordinate > 0) {
			user_data["x_coord"] = x_coordinate;
			user_data["y_coord"] = y_coordinate;
		} else if (x_coordinate === -1) {
			// Delete coordinate
			user_data["x_coord"] = 0;
			user_data["y_coord"] = 0;
		}
		const formData = new FormData();
		formData.append('image', inputSelectedFile[0]);
		formData.append('user', JSON.stringify(user_data));

		axios.put(`${Constants.BACKEND_URL}user`, formData,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}`, 'Content-Type': 'multipart/form-data' } })
			.then(response => {
				console.log("save User response : ", response.data);
				context["user"] = response.data.user
				context["auth_token"] = response.data.token
				localStorage.setItem('user', JSON.stringify(response.data.user));
				localStorage.setItem('token', response.data.token);
				setLoading(false);
				window.location.reload();
			}).catch(error => console.error(error));
	}
	function cancel() {
		if (window.confirm("Annuler les modifications ?")) {
			set_edit_page(false);
			setTimeout(() => {
				set_toulouse_map_marker_position(user_data);
			}, "200");
		}
	}
	function delete_account() {
		console.log("delete_account ");
		if (window.confirm("Supprimer le compte ? :'(\nSVP dites moi ce qui ne vous plait pas :\n   sorties-toulouse.fr@sorties-toulouse.fr\n")) {
			if (window.confirm("Êtes-vous sûr ? Cette action est irréversible !\nSVP dites moi ce qui ne vous plait pas :\n   sorties-toulouse.fr@sorties-toulouse.fr\n")) {
				axios.delete(`${Constants.BACKEND_URL}user`, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
					.then(response => {
						console.log("delete_account User response : ", response.data);
						localStorage.removeItem('user');
						localStorage.removeItem('token');
						window.location.reload();
					}).catch(error => console.error(error));
			}
		}
	}
	function set_godfather() {
		console.log("set_godfather");
		if (window.confirm("Déclarer comme parrain ? Cela lui donnera des points en récompense. Cette action ne peut être faite qu'une seule fois.")) {
			axios.post(`${Constants.BACKEND_URL}user/set_godfather`, {
				"_id": queryId,
				"title": user_data.title
			}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
				.then(response => {
					console.log("set_godfather response : ", response.data);
					var user_local_data = context["user"];
					user_local_data.godfather_title = "user_data.title";
					localStorage.setItem('user', JSON.stringify(user_local_data));
					window.location.reload();
				}).catch(error => console.error(error));
		}
	}
	function send_message() {
		console.log("send_message to user : ", queryId);
		axios.post(`${Constants.BACKEND_URL}private_message`, {
			"target_userId": queryId,
			"content": $('#message').val()
		}, { headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("save message response : ", response.data);
				get_messages(user_data);
			}).catch(error => console.error(error));
		$('#message').val("");
	}
	function getAge(dateString) {
		if (!dateString) {
			return dateString;
		}
		var today = new Date();
		var birthDate = new Date(dateString);
		var age = today.getFullYear() - birthDate.getFullYear();
		var m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	}
	function toDateString(date) {
		var pad = function (num) {
			return (num < 10 ? '0' : '') + num;
		};
		try {
			var date_object = new Date(date);
			return date_object.getFullYear() +
				'-' + pad(date_object.getMonth() + 1) +
				'-' + pad(date_object.getDate());
		} catch {
			return "";
		}
	}
	function slow_reload() {
		setTimeout(function () {
			window.location.reload();
		}, 100);
	}
	function saveCoordinate(e) {
		if (edit_page) {
			var rect = document.getElementById("Toulouse_Map").getBoundingClientRect();
			var x_coordinate_update = e.clientX / window.innerWidth;
			var y_coordinate_update = (e.clientY - rect.top) / window.innerWidth;
			var marker_size = window.innerWidth / marker_size_factor;
			set_position_marker_size(marker_size);
			set_x_coordinate(x_coordinate_update);
			set_y_coordinate(y_coordinate_update);
			set_x_relative_coordinate(e.pageX - marker_size * 1.5);
			set_y_relative_coordinate(e.pageY - marker_size * 1.5);
		}
	}
	function delete_coordinate() {
		set_x_coordinate(-1);
		set_y_coordinate(-1);
		set_x_relative_coordinate(-1);
		set_y_relative_coordinate(-1);
		set_position_marker_size(0);
	}
	function modify() {
		set_edit_page(true);
		setTimeout(() => {
			set_toulouse_map_marker_position(user_data);
		}, "200");
	}

	return (
		<div>
			<Header notif_count={notif_count} />

			<TopContainer>

				<UserDetails>
					<HorizontalWrapper>
						{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
						{is_user && edit_page && !isLoading && (
							<HorizontalWrapper>
								<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink></Location>
								<Location>&nbsp;</Location><Location>&nbsp;</Location>
								<Location><StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink></Location>
							</HorizontalWrapper>
						)}
						{is_user && !edit_page && (
							<StyledLink $isFullLink onClick={() => modify()}>
								Modifier
							</StyledLink>
						)}

						{link === "null" && !is_user && (
							<StyledLink $isFullLink onClick={() => create_user_link_data()}>
								Ajouter aux contacts
							</StyledLink>
						)}
						{link.status === "request_from_user" && (
							<StyledLink $isFullLink onClick={() => update_user_link_data("valid")}>
								Accepter le contact
							</StyledLink>
						)}
						{link.status === "request_to_user" && (
							<StyledLink>
								Demande de contact envoyée
							</StyledLink>
						)}
						{link.status === "request_to_user" && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Annuler la demande de contact
							</StyledLink>
						)}
						{link.status === "valid" && (
							<StyledLink $isFullLink onClick={() => delete_user_link_data()}>
								Retirer des contacts
							</StyledLink>
						)}
						{!is_user && context["user"] && !context["user"].godfather_title && (
							<StyledLink $isFullLink onClick={() => set_godfather()}>
								Déclarer comme parrain
							</StyledLink>
						)}
					</HorizontalWrapper>
				</UserDetails>
				<UserWrapper>
					<a href={`${Constants.BACKEND_URL}images/${user_data.image}`}>
						<div class="center-cropped" style={{ backgroundImage: `url('${Constants.BACKEND_URL}images/${user_data.image}')`, width: '10em', height: '10em', borderRadius: '20%' }} />
					</a>
					<UserDetails>
						{edit_page && <TitleWrapper><Location><input type="file" onChange={(e) => setInputSelectFile(e.target.files)} /></Location><Title>Modifier l'image</Title></TitleWrapper>}

						{edit_page ? (<TitleWrapper>
							<Location><input id="title" defaultValue={user_data.title} /></Location><Title>Nom affiché</Title></TitleWrapper>
						) : (<Title>{user_data.title}</Title>)}

						{edit_page ? (<TitleWrapper>
							<Location><input id="name" defaultValue={user_data.name} /></Location><Title>Prénom</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="surname" defaultValue={user_data.surname} /></Location><Title>Nom</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><span>{user_data.email}</span></Location><Title>Mail</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="phone" placeholder="06XXXXXXXX" maxlength="10" defaultValue={user_data.phone} /></Location><Title>Téléphone</Title></TitleWrapper>
						) : ("")}

						{edit_page ? (<TitleWrapper>
							<Location><input id="city" defaultValue={user_data.city} /></Location><Title>Ville</Title></TitleWrapper>
						) : (<span>{user_data.city}</span>)}


						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="gender" >
									<option value="null" selected={user_data.gender === "null"} ></option>
									<option value="m" selected={user_data.gender === "m"} >Homme</option>
									<option value="f" selected={user_data.gender === "f"} >Femme</option>
								</select>
							</Location>
							<Title>Genre</Title>
						</TitleWrapper>
						) : ("")}

						{edit_page && user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" defaultValue={toDateString(user_data.birthday)} /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{edit_page && !user_data.birthday ? (<TitleWrapper>
							<Location><input id="birthday" type="date" /></Location><Title>Date de naissance</Title></TitleWrapper>
						) : ("")}
						{user_data.birthday ? (
							<Details>{getAge(user_data.birthday)}&nbsp;Ans</Details>
						) : ("")}


						{edit_page ? (<TitleWrapper>
							<Location>
								<select id="access">
									<option value="public" selected={user_data.access === "public"} >Tous les utilisateurs connectés</option>
									<option value="friends_only" selected={user_data.access === "friends_only"} >Amis seulement</option>
								</select>
							</Location><Details>Visibilité</Details></TitleWrapper>
						) : (<TitleWrapper><Details>Visibilité&nbsp;:&nbsp;&nbsp;</Details><TextLabels>
							{"public" === user_data.access ? ("Tous les utilisateurs connectés") : ("")}
							{"friends_only" === user_data.access ? ("Amis seulement") : ("")}
							{"private" === user_data.access ? ("Seulement moi") : ("")}</TextLabels>
						</TitleWrapper>)}

						{user_data.last_connection ? (
							<div>
								<TitleWrapper><Details>Derniere connexion &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.last_connection), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Derniere modification &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.modified), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Compte crée &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{dateFormat(new Date(user_data.created), "d mmm yyyy")}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Nombre de vue &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.view_count}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score du mois en cours&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.month_score}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score du dernier mois&nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.last_month_score}</TextLabels></TitleWrapper>
								<TitleWrapper><Details>Score &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.score}</TextLabels></TitleWrapper>
								{user_data.godfather_title && (
									<TitleWrapper><Details>Parain &nbsp;:&nbsp;&nbsp;</Details><TextLabels>{user_data.godfather_title}</TextLabels></TitleWrapper>
								)}
							</div>
						) : ("")}
					</UserDetails>
				</UserWrapper>
				<UserWrapper>
					<UserDetails>
						<TitleWrapper>{edit_page ? (
							<textarea id="description" placeholder="Ecrit ce que tu veux !"
								rows={12} style={{ width: '98%' }} maxLength={10000} wrap="soft" defaultValue={user_data.description} />
						) : (
							<Title><Linkify><div style={{ whiteSpace: "pre-wrap" }}>{user_data.description}</div></Linkify></Title>
						)}
						</TitleWrapper>
					</UserDetails>
				</UserWrapper>
				{link.status === "valid" && (
					<TopMessagesWrapper>
						<MessagesWrapper>
							<MessageCardsContainer>
								{Array.isArray(private_messages) && (
									private_messages?.map((msg) => (
										<PrivateMessageCard key={`msg-${msg._id}`}
											content={msg.content}
											created={msg.created}
											user_title_hidden={(msg.userId === queryId) ? (user_data.title) : (context["user"].title)}
											user_image={(msg.userId === queryId) ? (`${Constants.BACKEND_URL}images/${user_data.image}`) : (`${Constants.BACKEND_URL}images/${context["user"].image}`)}
										/>
									)))}
							</MessageCardsContainer>
						</MessagesWrapper>
						<MessagesWrapper>
							<StyledLink $isFullLink onClick={() => send_message()}>&gt;</StyledLink>
							<InputMessage>
								<textarea id="message" rows={3} style={{ width: '98%' }} maxLength={1000} wrap="soft"
									placeholder="Ajouter un message privé" />
							</InputMessage>
						</MessagesWrapper>
					</TopMessagesWrapper>
				)}
				<UserWrapper>&nbsp;</UserWrapper>
				{!edit_page && activity_user_link_data.length !== 0 && (
					<div>{activity_user_link_data.length}&nbsp;sorties</div>
				)}
				{!edit_page && (
					<TopActivitiesWrapper>
						{Array.isArray(activity_user_link_data) &&
							activity_user_link_data?.map((link) => (
								<Link key={`activity-${link.activityId}`} to={`/activity/${link.activityId}`}>
									<ActivitySmallCard
										title={link.activity_title}
										date={link.activity_date}
										picture={`${Constants.BACKEND_URL}images/${link.activity_image}`}
									/>
								</Link>
							))}
					</TopActivitiesWrapper>
				)}
				<UserWrapper>&nbsp;</UserWrapper>
				{!edit_page && user_review.length !== 0 && (
					<div>{user_review.length}&nbsp;avis sur les expériences vécues</div>
				)}
				{!edit_page && (
					<TopReviewWrapper>
						{Array.isArray(user_review) &&
							user_review?.map((review) => (
								<ReviewCard key={`msg-${review._id}`}
									content={review.comment}
									rating={review.rating}
									activityId={review.activityId}
									user_id={review.userId}
									user_title={review.user_title}
									user_image={`${Constants.BACKEND_URL}images/${review.user_image}`}
								/>
							))}
					</TopReviewWrapper>
				)}

				<UserWrapper>&nbsp;</UserWrapper>
				{!edit_page && contact_list.length !== 0 && (
					<div>{contact_list.length}&nbsp;contacts</div>
				)}
				{!edit_page && (
					<TopActivitiesWrapper>
						{Array.isArray(contact_list) &&
							contact_list?.map((contact) => (
								<Link key={`user-${contact.userId}`} to={`/user/${contact.userId}`} onClick={() => slow_reload()}>
									<UserSmallCard
										title={contact.user_title}
										label={contact.user_city}
										picture={`${Constants.BACKEND_URL}images/${contact.user_image}`}
									/>
								</Link>
							))}
					</TopActivitiesWrapper>
				)}
				<div>&nbsp;</div>
				{is_user && !edit_page && (
					<UserWrapper>
						<StyledLink $isFullLink onClick={() => delete_account()}>Supprimer mon compte</StyledLink>
					</UserWrapper>
				)}
			</TopContainer>
			{!edit_page && (
				<div><div>Partager ce profil</div>
					<QRCodeSVG value={`https://sorties-toulouse.fr/user/${user_id}`} marginSize='4' />
					<div>&nbsp;</div></div>
			)}
			{position_marker_size > 0 && !edit_page ? (
				<div>Position approximative :</div>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<Location><StyledLink $isFullLink onClick={() => save()}>Sauvegarder</StyledLink>
					<StyledLink $isFullLink onClick={() => delete_coordinate()}>Supprimer la position</StyledLink>
					<Location><StyledLink $isFullLink onClick={() => cancel()}>Annuler</StyledLink></Location></Location>
			) : ("")}
			{edit_page && x_coordinate >= 0 ? (
				<div>Sélectionne une position approximative, ATTENTION A NE PAS ZOOMER CAR CELA NE FONCTIONNE PAS AVEC LE ZOOM !</div>
			) : ("")}
			{position_marker_size > 0 || (edit_page && x_coordinate >= 0) ? (
				<CardImage id="Toulouse_Map" src={`${Constants.BACKEND_URL}images/Toulouse_map.jpg`} onClick={(e) => saveCoordinate(e)} alt="Toulouse_Map" />
			) : ("")}
			{x_relative_coordinate && x_relative_coordinate > 0 ? (
				<PositionDiv style={{
					top: `${y_relative_coordinate}px`, left: `${x_relative_coordinate}px`, position: "absolute",
					border: `${position_marker_size / 5}px solid red`, width: `${position_marker_size * 3}px`, height: `${position_marker_size * 3}px`
				}} />
			) : ("")}
			<div>&nbsp;</div>
		</div>
	)
}

export default User
