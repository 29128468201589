import styled from 'styled-components'
import * as Constants from '../Constants';
import { Link } from 'react-router-dom'
import ActivityCard from '../components/ActivityCard'
import ActivitySmallCard from '../components/ActivitySmallCard'
import colors from '../utils/style/colors'
import { Loader } from '../utils/style/Atoms'
import { useState, useEffect, useContext } from 'react';
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import Header from '../components/Header'
import axios from "axios"

const CardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	align-items: center;
	justify-items: center;
`
const SmallCardsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	align-items: center;
	justify-items: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const PageSubtitle = styled.h2`
	font-size: 20px;
	color: ${colors.secondary};
	font-weight: 300;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
export const StyledLink = styled(Link)`
	padding: 10px 15px;
	color: #ffffff;
	text-decoration: none;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: 0.2em;
	${(props) =>
		props.$isFullLink &&
		`color: white; 
		border-radius: 30px; 
		background-color: ${colors.primary};`}
`
function Activities() {
	const [activities_array, setActivityArray] = useState(null);
	const [my_activities_array, setMyActivitiesArray] = useState(null);
	const [notif_count, set_notif_count] = useState(0);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);
	const { context } = useContext(UserContext)
	console.log("isLoading = ", isLoading)
	const navigate = useNavigate();

	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		setLoading('Chargement en cours...');
		const source = axios.CancelToken.source();

		axios.get(`${Constants.BACKEND_URL}activity_user_link/my_activities`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("responseezf : ", response);
				//alert(JSON.stringify(response));
				setMyActivitiesArray(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});

		console.log("gets all axios");
		axios.get(`${Constants.BACKEND_URL}activity`,
			{ cancelToken: source.token, headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				setLoading(false);
				console.log("responseezf : ", response);
				setActivityArray(response.data);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});

		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
				setError(JSON.stringify(error));
			});

		return () => {
			source.cancel();
		}
	}, [])

	return (
		<div>
			<Header notif_count={notif_count} />
			<LoaderWrapper>
				<StyledLink $isFullLink to={`/createActivity`} >Créer une sortie</StyledLink>
			</LoaderWrapper>
			{my_activities_array && my_activities_array.length > 0 ? (
				<div style={{ width: '100%' }}>Mes&nbsp;sorties&nbsp;({my_activities_array.length})</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper></LoaderWrapper>
			) : (
				<SmallCardsContainer>
					{my_activities_array?.map((activity) => (
						<Link key={`activity-${activity.activityId}`} to={`/activity/${activity.activityId}`}>
							<ActivitySmallCard
								title={activity.activity_title}
								date={activity.activity_date}
								picture={`${Constants.BACKEND_URL}images/${activity.activity_image}`}
							/>
						</Link>
					))}
				</SmallCardsContainer>
			)}
			{activities_array ? (
				<div style={{ width: '100%' }}>{activities_array.length}&nbsp;Sorties publiques listées</div>
			) : ("")}
			{isLoading ? (
				<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>
			) : (
				<CardsContainer>
					{activities_array?.map((activity) => (
						<Link key={`activity-${activity._id}`} to={`/activity/${activity._id}`}>
							<ActivityCard
								label={activity.current_user_number + "/" + activity.max_user_number + " " + activity.title}
								date={activity.date}
								picture={`${Constants.BACKEND_URL}images/${activity.image}`}
							/>
						</Link>
					))}
				</CardsContainer>
			)}
			<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			<PageSubtitle>{error && <p>{error}</p>}</PageSubtitle>
		</div>
	)
}

export default Activities
