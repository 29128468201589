import * as Constants from '../Constants';
import { useState, useEffect, useContext } from 'react';
import PodiumCard from '../components/PodiumCard'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import { UserContext } from '../utils/context'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { Loader } from '../utils/style/Atoms'
import axios from "axios"
import Header from '../components/Header'

const CardsContainer = styled.div`
	display: grid;
	gap: 0px;
	//grid-template-rows: 50px 20px;
	grid-template-columns: repeat(1, 1fr);
	align-items: center;
	justify-items: left;
`
const ActivityContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ActivityWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	padding: 0px 0;
	margin: 0 0px;
`
const LoaderWrapper = styled.div`
	display: flex;
	justify-content: center;
`
const PageTitle = styled.h1`
	font-size: 30px;
	text-align: center;
	padding-bottom: 30px;
	color: #ffffff;
`

function Podium() {
	const { context } = useContext(UserContext);
	const [podium_data, set_podium_data] = useState({});
	const [month_podium_data, set_month_podium_data] = useState({});
	const [last_month_podium_data, set_last_month_podium_data] = useState({});
	const [notif_count, set_notif_count] = useState(0);
	const [isLoading, setLoading] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!context["auth_token"]) {
			if (!localStorage.getItem('token')) { navigate('/login'); return; }
			context["user"] = JSON.parse(localStorage.getItem('user'));
			context["auth_token"] = localStorage.getItem('token');
			console.log("localStorage user : ", context["user"]);
			console.log("localStorage token : ", context["auth_token"]);
		}
		setLoading('Chargement en cours...');
		get_podium_data();
		axios.get(`${Constants.BACKEND_URL}notification/count`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("notification  count : ", response.data);
				set_notif_count(response.data);
			}).catch(error => {
				console.error("get activities error : ", error);
			});
	}, [])

	function get_podium_data() {
		console.log("get get_podium_data");
		axios.get(`${Constants.BACKEND_URL}user/podium/score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get score : ", response.data);
				set_podium_data(response.data);
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}user/podium/month_score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_n month_score : ", response.data);
				set_month_podium_data(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});

		axios.get(`${Constants.BACKEND_URL}user/podium/last_month_score`,
			{ headers: { Authorization: `Bearer ${context["auth_token"]}` } })
			.then(response => {
				console.log("get_ last_month_score : ", response.data);
				set_last_month_podium_data(response.data);
			}).catch(error => {
				if (error.response.status === 401) { navigate('/login'); return; }
				console.error(error);
			});
	}

	return (
		<div>
			<Header notif_count={notif_count} />
			<div class="normal">Soit actif sur le site et obtient des points 😄</div>
			<div class="normal">Parrainer quelqu'un et créer une belle sortie sont les actions qui rapportent le plus de points 👏</div>
			<div class="gros"><mark>&nbsp;Jeu concours : 10€ offert&nbsp;</mark> via Wero à l'utilisateur le plus actif ce mois-ci ! 😎</div>
			<ActivityContainer>
				Podium du mois en cours
				{isLoading ? (<LoaderWrapper><Loader data-testid="loader" /></LoaderWrapper>) : ("")}
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(month_podium_data) && (
							month_podium_data?.map((user) => (
								<Link key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.month_score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				Podium du mois dernier
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(last_month_podium_data) && (
							last_month_podium_data?.map((user) => (
								<Link key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.last_month_score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				Podium de tous les temps
				<ActivityWrapper>
					<CardsContainer>
						{Array.isArray(podium_data) && (
							podium_data?.map((user) => (
								<Link key={user._id} to={`/user/${user._id}`}>
									<PodiumCard
										content={user.title}
										user_image={`${Constants.BACKEND_URL}images/${user.image}`}
										score={user.score}
									/>
								</Link>
							)))}
					</CardsContainer>
				</ActivityWrapper>
				<PageTitle>{isLoading && <p>{isLoading}</p>}</PageTitle>
			</ActivityContainer>
		</div>
	)
}

export default Podium
