import { Component } from 'react'
import styled from 'styled-components'
import colors from '../utils/style/colors'
import dateFormat from 'dateformat';
import { i18n } from "dateformat";

i18n.dayNames = [
  "Dim",
  "Lun",
  "Mar",
  "Mer",
  "Jeu",
  "Ven",
  "Sam",
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
];

i18n.monthNames = [
  "Jan",
  "Fev",
  "Mar",
  "Avr",
  "Mai",
  "Juin",
  "Juil",
  "Aou",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

i18n.timeNames = ["a", "p", "am", "pm", "A", "P", "AM", "PM"];

const CardLabel = styled.span`
	color: #ffffff;
	font-size: 0.8em;
	font-weight: normal;
`
const CardWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 0.6em;
	background-color: ${colors.backgroundDark};
	border-radius: 30px;
	&:hover {cursor: pointer;}

`
const CardTitle = styled.div`
	color: #ffffff;
	font-size: 0.8em;
	font-weight: normal;
	align-self: center;
	height: 1.2em;
	display: flex;
	align-items: center;
`

class ActivityCard extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { picture, label, date } = this.props

		return (
			<CardWrapper onClick={this.setFavorite}>
				<CardLabel>{label}</CardLabel>
        <div class="center-cropped" style={{ backgroundImage: `url('${picture}')`, width: '9em', height: '9em', borderRadius: '15%' }} />
				<CardTitle>{dateFormat(new Date(date), "dddd d mmm HH:MM")}</CardTitle>
			</CardWrapper>
		)
	}
}

export default ActivityCard
